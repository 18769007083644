<template>
  <div
    v-if="showBar"
    class="d-flex shadow"
    :style="
      `padding: 3px;
      position: absolute;
      bottom: 0px;
      z-index: 90;
      background: #fff;
      border-top-right-radius: 5px;
      width: ${ hideBar ? '40px' : 'calc(100% - 17px)'};`
    "
  >
    <v-btn icon depressed tile @click="hideBar = !hideBar">
      <v-icon v-if="hideBar">mdi-information-outline</v-icon>
      <v-icon v-else>mdi-close</v-icon>
    </v-btn>
  <div v-if="!hideBar" class="py-1 px-2 body-2" style="width: 100%">
      <div>
        <div class="pb-1">
          <div
            class="d-flex justify-space-between body-2"
            v-if="getValueToMinimum < 100"
          >
            <span class="light-blue--text"
              >Reach the minimum price<v-icon small color="light-blue"
                >mdi-cart-plus</v-icon
              ></span
            >
            <span>{{
              `${getCartCurrency} ${(
                getMinimumOrderPrice - getCartTotalPrice
              ).toLocaleString({ minimumFractionDigits: 2 })} To Goal`
            }}</span>
          </div>
          <div
            class="d-flex justify-space-between"
            v-else-if="getValueToMinimum === 100 && getValueToFree < 100"
          >
            <span class="orange--text"
              >You're about to get a free delivery
              <v-icon small color="orange">mdi-truck-outline</v-icon></span
            >
            <span>{{
              `${getCartCurrency} ${(
                getFreeDeliveryPrice - getCartTotalPrice
              ).toLocaleString({ minimumFractionDigits: 2 })} To Goal`
            }}</span>
          </div>
          <span class="success--text" v-else
            >Awesome! You got a free delivery
            <v-icon color="success">mdi-truck-check</v-icon></span
          >
        </div>

        <!-- when price is not over the minimum -->
        <v-progress-linear
          v-if="getValueToMinimum < 100"
          color="light-blue"
          :value="getValueToMinimum"
          buffer-value="0"
          stream
        ></v-progress-linear>

        <!-- when price is over the minimum & reached the free delivery -->
        <v-progress-linear
          v-if="getValueToMinimum === 100 && getValueToFree < 100"
          color="orange"
          :value="getValueToFree"
          buffer-value="0"
          stream
        ></v-progress-linear>
      </div>

      <!-- <div v-if="isFreeDelivery">
        <span v-if="getFreeDeliveryPrice < 100">Add a bit more to get free delivery</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PriceReachBar",
  data() {
    return {
      hideBar: false,
    };
  },
  computed: {
    ...mapGetters("cart", [
      "getCartStore",
      "getCartTotalPrice",
      "isFreeDelivery",
      "getCartCurrency",
      "getActiveCartStoreId",
    ]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    isMinimum() {
      return this.getCartTotalPrice >= this.getMinimumOrderPrice;
    },
    getFreeDeliveryPrice() {
      return this.getCartStore?.freeDeliveryPrice;
    },
    getMinimumOrderPrice() {
      return this.getCartStore.minimumOrderPrice || 0;
    },
    getValueToMinimum() {
      const { minimumOrderPrice } = this.getCartStore;
      const value = this.getCartTotalPrice / minimumOrderPrice;
      return value < 1 ? value * 100 : 100;
    },
    getValueToFree() {
      const { freeDeliveryPrice } = this.getCartStore;
      const value = this.getCartTotalPrice / freeDeliveryPrice;
      return value < 1 ? value * 100 : 100;
    },
    showBar() {
      return (
        this.getStoreIdParam &&
        this.getActiveCartStoreId === this.getStoreIdParam &&
        this.getFreeDeliveryPrice >= 0 &&
        this.getMinimumOrderPrice > 0
      );
    },
  },
};
</script>