// import { mdiAccount } from '@mdi/js'

const getNavigations = () => {
  return [
    {
      title: "app.route.stores",
      resource: "stores",
      icon: "mdi-store",
      link: "/#/stores",
      to: "stores",
      activeWhen: ["stores", "store", "section", "product"],
    },
    {
      title: "app.route.cart",
      resource: "cart",
      icon: "mdi-cart-variant",
      link: "/#/cart/:storeId",
      to: "cart",
      activeWhen: ["cart", "checkout"],
    },
    {
      title: "app.route.orders",
      resource: "orders",
      icon: "mdi-format-list-bulleted-type",
      link: "/#/orders",
      to: "orders",
      activeWhen: ["orders"],
    },
    {
      title: "app.route.favorites",
      resource: "favorites",
      icon: "mdi-heart",
      link: "/#/favorites/:storeId",
      to: "favorites",
      activeWhen: ["favorites"],
    },
    // {
    //   title: "app.route.offers",
    //   resource: "offers",
    //   icon: "mdi-tag-multiple-outline",
    //   link: "/#/offers",
    //   to: "offers",
    // },
  
    // driver
    {
      title: "driver.route.activeDelivery",
      resource: "delivery.active",
      icon: "mdi-truck-fast",
      link: "/#/delivery",
      to: "delivery.active",
      activeWhen: ["delivery.active"],
    },
  
    // admin
    {
      title: "admin.route.management",
      divider: true,
      group: true,
      groupId: "management",
    },
    {
      title: "admin.route.dashboards",
      resource: "admin.dashboards",
      icon: "mdi-chart-bar",
      link: "/#/admin/dashboards",
      to: "admin.dashboards",
      activeWhen: ["admin.dashboards"],
      groupId: "management",
    },
    {
      title: "admin.route.customers",
      resource: "admin.customers",
      icon: "mdi-account-multiple",
      link: "/#/admin/customers",
      to: "admin.customers",
      activeWhen: ["admin.customers"],
      groupId: "management",
    },
  
    {
      title: "admin.route.control",
      divider: true,
      group: true,
      groupId: "admin.control",
    },
    {
      title: "admin.route.users",
      resource: "admin.users",
      icon: "mdi-account-tie",
      link: "/#/admin/users",
      to: "admin.users",
      activeWhen: ["admin.users"],
      groupId: "admin.control",
    },
    {
      title: "admin.route.roles",
      resource: "admin.roles",
      icon: "mdi-shield-lock",
      link: "/#/admin/roles",
      to: "admin.roles",
      groupId: "admin.control",
    },
    {
      title: "admin.route.notifications",
      resource: "admin.notifications",
      icon: "mdi-email-send",
      link: "/#/admin/notifications",
      to: "admin.notifications",
      activeWhen: ["admin.notifications"],
      groupId: "admin.control",
    },
    {
      title: "admin.route.regionsStores",
      resource: "admin.regions",
      icon: "mdi-flag",
      link: "/#/admin/regions",
      activeWhen: ["admin.regions"],
      to: "admin.regions",
      groupId: "admin.control",
    },
    {
      title: "admin.route.dataTypes",
      resource: "admin.types",
      icon: "mdi-database-cog-outline",
      link: "/#/admin/datatypes",
      to: "admin.types",
      activeWhen: ["admin.types"],
      groupId: "admin.control",
    },
  
    {
      title: "app.route.manager",
      divider: true,
      group: true,
      groupId: "manager",
    },
  
    // priority for router params then object params
    // manager
    {
      title: "app.route.stats",
      resource: "manager.stats",
      icon: "mdi-chart-box-outline",
      link: "/#/manager/stats",
      to: "manager.stats",
      activeWhen: ["manager.stats"],
      groupId: "manager",
    },
    {
      title: "app.route.managedStores",
      resource: "manager.stores",
      icon: "mdi-store-outline",
      link: "/#/manager/store/profiles",
      to: "manager.stores",
      activeWhen: [
        "manager.store.profile",
        "manager.store.sections", "manager.store.section",
        "manager.store.categories", "manager.store.category",
        "manager.store.products", "manager.store.product",
      ],
      groupId: "manager",
    },
    // {
    //   title: "app.route.products",
    //   resource: "store.products",
    //   icon: "mdi-barcode-scan",
    //   link: "/#/manager/store/products",
    //   to: "store.products",
    //   activeWhen: ["store.products.list", "store.product"],
    //   groupId: "manager",
    // },
    {
      title: "app.route.dispatchers",
      resource: "manager.store.dispatchers",
      icon: "mdi-truck-delivery",
      link: "/#/manager/store/dispatchers",
      to: "manager.store.dispatchers",
      activeWhen: ["manager.store.dispatcher"],
      groupId: "manager",
    },
    {
      title: "admin.route.offers",
      resource: "manager.store.offers",
      icon: "mdi-tag-plus",
      link: "/#/admin/offers",
      to: "manager.store.offers",
      activeWhen: ["manager.store.offers"],
      groupId: "manager",
    },
  
    {
      title: "app.route.settings",
      divider: true,
      group: true,
      groupId: "settings",
    },
    {
      title: "app.route.accountSettings",
      resource: "settings",
      icon: "mdi-cog",
      link: "/#/settings",
      to: "settings",
      groupId: "settings",
    },
  ];
}

const navigations = getNavigations();

export default navigations;
