<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      :right="getLanguage === 'ar'"
      style="z-index: 98"
    >
      <!-- Logo Bar -->
      <app-logo :react="react" />

      <!-- Navigation Menu -->
      <navigation-menu
        style="
          position: absolute;
          width: 100%;
          height: calc(100vh - 88px);
          overflow-y: auto;
        "
      />
      <app-footer />
      <div class="right-inner-shadow" style="position: absolute; right: 0; width: 10px; height: 100%;"></div>
    </v-navigation-drawer>

    <!-- Application Bar -->
    <app-bar v-model="drawer" base-color="secondary">
      <template v-if="getViewData.title" #title>
        {{ getViewData.title }}
      </template>
    </app-bar>

    <v-main class="main-content d-flex align-start">
      <!-- View Content -->
      <view-container :auto-scroll="false">
        <transition name="slide-x-reverse-transition">
          <router-view></router-view>
        </transition>
      </view-container>
      <price-reach-Bar />
    </v-main>

    <!-- Native Notification -->
    <native-notification />

    <reactivity />

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NativeNotification from "@/common/components/NativeNotification";
import PriceReachBar from "@/common/components/PriceReachBar";
import AppBar from "@/common/components/AppBar";
import NavigationMenu from "@/common/components/NavigationMenu";
// import Heartbeat from "@/common/components/Heartbeat";
import Reactivity from "@/common/components/Reactivity";
import ViewContainer from "@/common/layouts/ViewContainer";
import AppFooter from "@/common/layouts/AppFooter";
import AppLogo from "@/common/layouts/AppLogo";

export default {
  name: "AppLayout",
  components: {
    NativeNotification,
    AppBar,
    AppLogo,
    NavigationMenu,
    ViewContainer,
    AppFooter,
    PriceReachBar,
    Reactivity,
    // Heartbeat,
  },
  data: () => ({
    drawer: true,
    react: false,
  }),
  computed: {
    ...mapGetters("ui", ["getLanguage", "getViewData"]),
    ...mapGetters("stores", ["getStoreById"]),

    // getBorderColor() {
    //   return "#00000055";
    //   // return this.$vuetify.theme.themes.light.primary + "75";
    // },
  },
  methods: {
    ...mapActions("stores", ["setSelectedStore", "clearSelectedStore"]),
    ...mapActions("ui", ["setViewData"]),
  },
};
</script>

<style>
.main-content {
  overflow: hidden;
  background-color: #fff;
}

.v-data-table td,
.v-data-table th {
  font-size: 0.75rem;
  padding: 0 8px;
}

.col-12 {
  padding-top: 0;
}
.v-card__title {
  word-break: break-word;
}
</style>
