<template>
  <v-footer class="pa-1" style="position: absolute; bottom: 0; width: 100%; z-index: -1;">
    <copyright />
    <!-- <v-spacer /> -->
    <!-- <span
      class="px-3 blue--text text--lighten-4 caption"
      v-text="getLoadingMessage"
    ></span>
    <v-progress-circular
      v-if="getLoadingState"
      :indeterminate="getLoadingState"
      color="blue lighten-4"
      size="20"
    ></v-progress-circular> -->
  </v-footer>
</template>

<script>
// import { mapGetters } from "vuex";
import Copyright from "@/common/components/Copyright";

export default {
  name: "AppFooter",
  components: {
    Copyright,
  },
  // computed: {
  //   ...mapGetters("ui", ["getLoadingState", "getLoadingMessage"]),
  // },
};
</script>
