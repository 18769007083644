<template>
  <div v-if="active" width="100" color="white" class="reactivity">
    <div>
      <div :class="`dot ${color}`"></div>
      <div :class="`dot ${color}`"></div>
      <div :class="`dot ${color}`"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reactivity",
  props: {
    color: {
      type: String,
      default() {
        return "white";
      },
    },
  },
  data() {
    return {
      active: false,
    };
  },
  mounted() {
    this.$sub("reactivity/*", () => {
      console.log("connected...");
      this.active = true;
      setTimeout(() => {
        this.active = false;
      }, 5000);
    });
  },
};
</script>

<style>
.reactivity {
  position: absolute;
  left: calc(50% - 21px);
  top: 5px;
  z-index: 999;
  transform: rotate(90deg);
  animation: dots 1s ease-in-out infinite;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  border-radius: 50%;
  animation: dot 1s ease-in-out infinite;
  background-color: #000;
}
.dot:nth-child(1) {
  animation-delay: 0s;
}
.dot:nth-child(2) {
  animation-delay: 0.25s;
}
.dot:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes dots {
  0%,
  20% {
    opacity: 0.2;
  }
  100% {
    opacity: 100;
  }
}

@keyframes dot {
  0%,
  20% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>