<template>
  <div>
    <!-- <v-icon>mdi-circle</v-icon> -->
    CC
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DeliveryStatus",
  computed: {
    ...mapGetters("order", ["getDriverActiveOrders"]),
  },
  // watch: {
  //   getDriverActiveOrders() {
  //     this.watchActiveDelivery();
  //   }
  // },
  methods: {
    watchActiveDelivery() {
      const hasDispatchedOrder = newOrders.some(order => ["DISPATCHED", "DELIVERY", "NEAR", "DELIVERED", "RETURNING"].includes(order.activeTrackStatus));
      if (hasDispatchedOrder) {
        this.$watchPosition();
      }
    }
  },
  created() {
    this.watchActiveDelivery();
  }
};
</script>
