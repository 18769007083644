<template>
  <v-app-bar color="primary" flat dark height="56">
    <img src="/name.svg" height="42" style="left: 6px; position: absolute" />
    <span
      class="secondary--text"
      style="left: 72px; top: 22px; font-size: 19pt; position: absolute"
      >{{ getSubname }}</span
    >
    <!-- <heartbeat style="position: absolute; right: 52px; margin-top: -30px" /> -->
    <img
      src="/logo-full.svg"
      height="42"
      style="right: 8px; position: absolute"
    />
    <v-divider
      :style="`border-width: 1px;
            background: ${getBorderColor};
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;`"
    />
  </v-app-bar>
</template>

<script>

export default{
  name: 'AppLogo',
  computed: {
    getSubname() {
      return this.$hasRole("DRIVER")
        ? "CAPTEN"
        : this.$hasRole("ADMIN") || this.$hasRole("SUPER-ADMIN")
        ? "ADMIN"
        : "";
    },
  }
}
</script>
